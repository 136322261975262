.floating-whatsapp-icon {
    position: fixed;
    bottom: 20px; /* Adjust distance from bottom as needed */
    right: 20px; /* Adjust distance from right as needed */
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    background-color:  #ff6a00; /* Adjust background color as needed */
    color: #fff; /* White color for icon */
    border-radius: 50%; /* Make it circular */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px; /* Adjust icon size as needed */
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Add shadow for depth effect */
    z-index: 9999; /* Ensure it's above other elements */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
  }
  
  .floating-whatsapp-icon:hover {
    background-color: #566aec; /* Darker green on hover */
  }
  
  
  .floating-call-icon {
    position: fixed;
    bottom: 20px; /* Adjust distance from bottom as needed */
    left: 20px; /* Adjust distance from left as needed */
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    background-color: #ff6a00; /* Adjust background color as needed */
    color: white; /* Adjust icon color as needed */
    border-radius: 50%; /* Make it circular */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px; /* Adjust icon size as needed */
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Add shadow for depth effect */
    z-index: 9999; /* Ensure it's above other elements */
  }
  
  .floating-call-icon:hover {
    background-color: #0056b3; /* Adjust hover background color as needed */
  }
  