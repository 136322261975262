/* Navbar.css */

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  background: rgba(255, 252, 252, 0); /* Semi-transparent background */
  z-index: 10;
  box-sizing: border-box;
  transition: background 0.3s ease;
}

.navbar.solid {
  background: rgba(0, 0, 0, 1); /* Solid black background */
}


.brand-name {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  width: 40px; /* Adjust size as needed */
  margin-right: 10px;
}

.brand-text {
  display: flex;
  align-items: center;
}

.advance {
  color: red;
  font-weight: bold;
  font-size: 24px;
}

.techno {
  color: white;
  font-weight: bold;
  font-size: 24px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
}

.nav-links li {
  cursor: pointer;
  color: #fff;
}

.nav-links li:hover {
  color: #ccc;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
}

.cta-button {
  padding: 10px 20px;
  background-color: orange;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: darkorange;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  padding-right: 20px;
}

.hamburger-menu .line {
  width: 25px;
  height: 3px;
  background-color: #fff;
}

.side-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  background-color: #33333387;
  padding: 60px 20px;
  z-index: 20;
  transform: translateX(100%);
  color: #fff;
  transition: transform 0.3s ease;
}

.side-menu.open {
  transform: translateX(0);
}

.side-menu .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu li {
  padding: 15px 0;
}

.side-menu li a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger-menu {
    display: flex;
  }

  .cta-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .cta-button {
    display: none;
  }

  .side-menu {
    display: block;
    top: 0;
    right: -100%;
    height: 100%;
    width: 150px;
    background-color: #252525e3;
    color: aliceblue;
    transition: right 0.3s ease;
  }

  .side-menu.open {
    right: 0;
  }

  .side-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .side-menu li {
    margin: 20px 0;
  }

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 5px;
  }

  .hamburger-menu .line {
    width: 30px;
    height: 3px;
    background-color: white;
  }
}
