/* SEO Banner */
.seo-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #fdfdfd;
    max-width: 1200px;
    margin: 0 auto; /* Center the banner within its container */
  }
  
  .seo-banner-content {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .seo-banner-image {
    flex: 1;
    max-width: 30%;
    padding: 20px;
  }
  
  .seo-banner-heading {
    margin-top: 0;
    text-align: left;
    font-size: 18px;
  }
  
  .seo-banner-content p {
    margin-bottom: 20px;
    text-align: justify;
    font-size: 13px;
  }
  
  .seo-banner-content button {
    padding: 10px 20px;
    background-color: #ff7113;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: 0 auto;
  }
  
  .seo-banner-content button:hover {
    background-color: #a54809;
  }
  
  .seo-banner-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .seo-banner {
      flex-direction: column;
    }
  
    .seo-banner-content, 
    .seo-banner-image {
      max-width: 100%;
    }
  }
  
  /* Embrace SEO Container */
  .embrace-seo-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .embrace-seo-heading {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .embrace-seo-subheading {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 30px;
  }
  
  .embrace-seo-domains {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .embrace-seo-domain-item {
    flex: 0 1 calc(20% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Light background color */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .embrace-seo-domain-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .embrace-seo-dark {
    background-color: #ff8000;
    color: #000000;
  }
  
  .embrace-seo-light {
    background-color: #fff;
    color: #000000;
  }
  
  .embrace-seo-domain-title {
    font-size: 1em;
    margin-bottom: 10px;
  }
  
  .embrace-seo-domain-description {
    font-size: 0.8em;
    color: #000000;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .embrace-seo-domain-item {
      flex-basis: calc(45% - 20px);
    }
  }
  
  @media (max-width: 576px) {
    .embrace-seo-domain-item {
      flex-basis: 100%;
    }
  }

  


  @import '@fortawesome/fontawesome-svg-core/styles.css';

.seo-we-are-best-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.seo-we-are-best-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.seo-we-are-best-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

.seo-we-are-best-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.seo-we-are-best-item {
  flex: 1 1 30%;
  max-width: 33%;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: left;
}

.seo-we-are-best-icon {
  font-size: 50px;
  color: #ff4800;
  margin-bottom: 15px;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.seo-we-are-best-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.seo-we-are-best-item p {
  font-size: 14px;
  color: #333;
}

@media (max-width: 768px) {
  .seo-we-are-best-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}




@import '@fortawesome/fontawesome-svg-core/styles.css';

.seo-our-seo-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
}

.seo-our-seo-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  /* color: #f9f9f9; */
}

.seo-our-seo-subtitle {
  font-size: 16px;
  /* color: #ffff; */
  margin-bottom: 30px;
}

.seo-our-seo-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.seo-our-seo-item {
  flex: 1 1 30%;
  max-width: 33%;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #000000;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: left;
}

.seo-our-seo-icon {
  font-size: 50px;
  color: #ff4000e8;
  margin-bottom: 15px;
}

.seo-our-seo-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.seo-our-seo-item p {
  font-size: 14px;
  color: #ffffff;
}

@media (max-width: 768px) {
  .seo-our-seo-item {
    flex: 1 1 100%;
    max-width: 47%;
    
    
  }
  .seo-our-seo-item{
    margin: 5px;
  }
}
@media (max-width: 560px) {
    .seo-our-seo-item {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
  
