.reachout-container {
  text-align: center;
  border-radius: 10px;

  margin: 40px 0;
}

.reachout-container h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.reachout-methods {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.reachout-method {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1 1 calc(20% - 20px);
  max-width: calc(20% - 20px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.reachout-method:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
}

.reachout-icon {
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 15px;
}

.reachout-method h3 {
  margin-bottom: 10px;
  color: #007bff;
  font-size: 1.5rem;
}

.reachout-method p {
  margin: 0;
  color: #555;
  font-size: 1rem;
}

.reachout-method a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.reachout-method a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .reachout-methods {
    flex-direction: column;
  }

  .reachout-method {
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
}
