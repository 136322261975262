.homepage {
    font-family: Arial, sans-serif;
  }
  
  .homepage-banner {
    position: relative;
    text-align: center;
    color: white;
    padding: 0;
    margin: 0;
  }
  
  .homepage-banner-image {
    width: 100%;
    max-height: 100vh;
    min-height: 70vh;
    height: 100%;
    filter: brightness(50%);
  }
  
  .homepage-banner-content {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    width: 90%; /* Adjust width for better responsiveness */
  }
  
  .homepage-banner-content p {
    font-style: italic;
    margin-bottom: 20px;
    font-size: 1vw; /* Responsive font size */
  }
  
  .homepage-banner-content h1 {
    font-style: italic;
    margin-bottom: 20px;
    font-size: 2.5vw; /* Responsive font size */
  }
  
  .homepage-banner-content strong {
    font-size: 1vw; /* Responsive font size */
    color: orange;
  }
  
  .homepage-cta-button {
    padding: 10px 20px;
    background-color: #ff8401;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2vw; /* Responsive font size */
  }
  
  .homepage-cta-button:hover {
    background-color: #ffb326;
  }
  
  /* Media Queries for different screen sizes */
  @media (max-width: 1200px) {
    .homepage-banner-content p {
      font-size: 1.2vw;
    }
  
    .homepage-banner-content h1 {
      font-size: 3.5vw;
    }
  
    .homepage-banner-content strong {
      font-size: 1.2vw;
    }
  
    .homepage-cta-button {
      font-size: 1.5vw;
    }
  }
  
  @media (max-width: 768px) {
    .homepage-banner-content p {
      font-size: 2vw;
    }
  
    .homepage-banner-content h1 {
      font-size: 4.5vw;
    }
  
    .homepage-banner-content strong {
      font-size: 2vw;
    }
  
    .homepage-cta-button {
      font-size: 2vw;
    }
  }
  
  @media (max-width: 480px) {
    .homepage-banner-content p {
      font-size: 2.5vw;
    }
  
    .homepage-banner-content h1 {
      font-size: 5.5vw;
    }
  
    .homepage-banner-content strong {
      font-size: 2.5vw;
    }
  
    .homepage-cta-button {
      font-size: 2.5vw;
    }
  }
  


  .heading-services{
    text-align: center;
  }
  
  
  









  .ourway-container {
  background-color: #121212;
  color: white;
  padding: 50px 20px;
  text-align: center;
}

.ourway-content {
  margin: 0 auto;
}

.ourway-title {
  color: #ff4500;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.ourway-heading {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.ourway-description {
  font-size: 1rem;
  margin-bottom: 40px;
}

.ourway-circles {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.ourway-circle {
  border: 2px solid #ff4500;
  border-radius: 50%;
  padding: 20px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  text-align: center;
}

.ourway-circle h3 {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.ourway-circle p {
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ourway-heading {
    font-size: 2rem;
  }

  .ourway-description {
    font-size: 0.9rem;
  }

  .ourway-circle {
    width: 150px;
    height: 150px;
  }

  .ourway-circle h3 {
    font-size: 1rem;
  }

  .ourway-circle p {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .ourway-heading {
    font-size: 1.5rem;
  }

  .ourway-description {
    font-size: 0.8rem;
  }

  .ourway-circle {
    width: 120px;
    height: 120px;
  }

  .ourway-circle h3 {
    font-size: 0.9rem;
  }

  .ourway-circle p {
    font-size: 0.7rem;
  }
}
