.website {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .platform-info {
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .platform-info-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333;
  }
  
  .platform-info-item {
    flex: 0 1 calc(30% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Light background color */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .platform-info-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .platform-info-logo {
    margin-bottom: 10px;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000; /* White background for logo */
    border-radius: 50%; /* Make logo container round */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .platform-info-logo img {
    width: 55px;
    height: 80px;
    object-fit: contain;
  }
  
  .platform-info-heading {
    margin-top: 10px;
    text-align: center;
    font-size: 1rem;
    color: #333;
  }
  
  .platform-info-description {
    color: #666;
    text-align: justify;
    margin-top: 10px;
    font-size: 0.8rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .platform-info-item {
      flex-basis: calc(42% - 0px);
    }
  }
  
  @media (max-width: 576px) {
    .platform-info-item {
      flex-basis: 100%;
    }
  }
  





  .website-design-dev-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .website-design-dev-title {
    font-size: 2.5em;
    color: #d32f2f;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .website-design-dev-title .highlight {
    color: #333;
  }
  
  .website-design-dev-main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .website-design-dev-text {
    flex: 0;
    margin-right: 20px;
    text-align: justify;
  }
  
  .website-design-dev-text p {
    margin-bottom: 15px;
    line-height: 1.8;
    text-align: justify;
  }
  
  .website-design-dev-illustration {
    flex: 1;
    text-align: center;
  }
  
  .website-design-dev-illustration img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .website-design-dev-features {
    margin-top: 30px;
    text-align: center;
  }
  
  .website-design-dev-features h2 {
    font-size: 1em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .website-design-dev-features ul {
    list-style-type: none;
    padding: 0;
    display: inline-block;
    text-align: left;
  }
  
  .website-design-dev-features li {
    margin: 10px 0;
    position: relative;
    padding-left: 25px;
    line-height: 1.5;
  }
  
  .website-design-dev-features li:before {
    content: '✔';
    color: #d32f2f;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  @media (min-width: 768px) {
    .website-design-dev-main-content {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .website-design-dev-text {
        flex: 2;
        margin-right: 0px ;
        text-align: justify;
      }
      
  
    .website-design-dev-features ul {
      text-align: center;
    }
  }
  
  @media (min-width: 1024px) {
    .website-design-dev-title {
      font-size: 3em;
    }
  
    .website-design-dev-features h2 {
      font-size: 2em;
    }
  }
  





  
.expertise-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .expertise-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  .expertise-item {
    flex: calc(45% - 20px); /* Three items per row with spacing */
    margin: 10px;
    padding: 20px;
    border: 1px solid #000000;
    border-radius: 5px;
    background-color: #ffffff;
    transition: transform 0.3s ease;

  }
  
  .expertise-item:hover {
    transform: translateY(-5px); /* Add a hover effect */
  }
  
  .expertise-heading {
    margin-top: 0;
    font-size: 16px;
  }
  
  .expertise-description {
    color: #666;
    font-size: 13px;
  }
  
  


  .testimonials-container {
    width: 100%;
    color: orange;
    text-align: center;
    margin: auto;
    background-color: black;
    /* padding: 50px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
  }
  
  .testimonials-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .testimonial-card {
    background-color: #222;
    border-radius: 5px;
    color: white;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    flex: 1 0 300px;
    max-width: 400px;
    text-align: center;
  }
  
  .testimonial-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    border: 2px solid white;
  }
  
  .testimonial-card h3 {
    color: white;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .rating {
    color: goldenrod;
    margin-bottom: 10px;
  }
  
  .testimonial-card p {
    color: white;
    font-size: 13px;
  }
  
  @media (max-width: 767px) {
    .testimonial-card {
      /* flex: 100%; */
      width: 100%;

    }
  }
  
  