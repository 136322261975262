.homepage {
  font-family: Arial, sans-serif;
}

.homepage-banner {
  position: relative;
  text-align: center;
  color: white;
  padding: 0;
  margin: 0;
}

.homepage-banner-image {
  width: 100%;
  height: 100vh; /* Full height for larger screens */
  object-fit: cover; /* Cover the container */
  filter: brightness(50%);
}

.homepage-banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 90%; /* Adjust width for better responsiveness */
}

.homepage-banner-content p {
  font-style: italic;
  margin-bottom: 20px;
  font-size: 1vw; /* Responsive font size */
}

.homepage-banner-content h1 {
  font-style: italic;
  margin-bottom: 20px;
  font-size: 2.5vw; /* Responsive font size */
}

.homepage-banner-content strong {
  font-size: 1vw; /* Responsive font size */
  color: orange;
}

.homepage-cta-button {
  padding: 10px 20px;
  background-color: #ff8401;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2vw; /* Responsive font size */
}

.homepage-cta-button:hover {
  background-color: #ffb326;
}

/* Media Queries for different screen sizes */
@media (max-width: 1200px) {
  .homepage-banner-content p {
    font-size: 1.2vw;
  }

  .homepage-banner-content h1 {
    font-size: 3.5vw;
  }

  .homepage-banner-content strong {
    font-size: 1.2vw;
  }

  .homepage-cta-button {
    font-size: 1.5vw;
  }
}

@media (max-width: 768px) {
  .homepage-banner-image {
    height: 70vh; /* Reduce height for tablets */
  }

  .homepage-banner-content p {
    font-size: 2vw;
  }

  .homepage-banner-content h1 {
    font-size: 4.5vw;
  }

  .homepage-banner-content strong {
    font-size: 2vw;
  }

  .homepage-cta-button {
    font-size: 2vw;
  }
}

@media (max-width: 480px) {
  .homepage-banner-image {
    height: 100vw; /* Set height to width for square shape on mobile */
  }

  .homepage-banner-content p {
    font-size: 2.5vw;
  }

  .homepage-banner-content h1 {
    font-size: 5.5vw;
  }

  .homepage-banner-content strong {
    font-size: 2.5vw;
  }

  .homepage-cta-button {
    font-size: 2.5vw;
  }
}








  .homepage-perks {
    padding: 50px 20px;
    background-color: #f8f9fa;
    text-align: center;
    max-width: 1200px;
    margin: auto; /* Center content horizontally */
}

  .homepage-perks-title {
    font-size: 2em;
    margin-bottom: 30px;
    color: #0a437c; /* Change the color to match the theme */
  }
  
  .homepage-perks-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .homepage-perk {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    max-width: 300px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* More prominent shadow */
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
  }
  
  .homepage-perk:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
  }
  
  .homepage-perk-icon {
    font-size: 2.5em; /* Increase icon size */
    margin-bottom: 10px;
    color: #f3c019; /* Change the icon color to a premium gold-like color */
  }
  
  .homepage-perks-button {
    padding: 15px 30px;
    margin-top: 30px;
    background-color: #f3c019;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add shadow to button */
    transition: background-color 0.2s, box-shadow 0.2s; /* Smooth transition for hover effects */
  }
  
  .homepage-perks-button:hover {
    background-color: #d4a517; /* Darken the button color on hover */
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
  }
  
  @media (max-width: 768px) {
    .homepage-perks-container {
      flex-direction: column;
      align-items: center;
    }
  }
  



  




  .homepage-about {
    font-family: 'Arial', sans-serif;
    background-color: #ffffff;
    color: #000000;
    padding: 10px;
  }
  
  .homepage-about-section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .homepage-about-left,
  .homepage-about-right {
    flex: 1;
    padding: 10px;
  }
  
  .homepage-about-left {
    max-width: 450px;
  }
  
  .homepage-about-right {
    max-width: 800px;
  }
  
  .homepage-about-us {
    margin-bottom: 20px;
  }
  
  .homepage-about-us h2 {
    font-size: 1.5em;
    color: #333333;
  }
  
  .homepage-about-us h3 {
    font-size: 1.2em;
    margin-bottom: 20px;
    color: #ff6a00;
    font-weight: 700;
  }
  
  .homepage-about-services {
    margin-top: 20px;
    align-items: left;

  }
  
  .homepage-about-service {
    margin-bottom: 20px;
    display: flex;
    align-items: left;
    flex-direction: column;
  }
  
  .homepage-about-sect{
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    font-weight: 700;
  }
  .homepage-about-service p {
    margin: 0 10px;
    font-size: 1em;
    color: #555555;
  }
  
  .homepage-about-progress {
    background-color: #dcdcdc;
    border-radius: 5px;
    overflow: hidden;
    width: 100%; /* Adjusted width for consistency */
    height: 5px; /* Standardized height */
    margin-left: 0px;
    position: relative;
  }
  
  .homepage-about-progress-bar {
    height: 100%;
    background-color: #ee3232;
    position: absolute;
  }
  
  .homepage-about-progress-bar[data-progress="90"] {
    width: 90%;
  }
  
  .homepage-about-progress-bar[data-progress="88"] {
    width: 88%;
  }
  
  .homepage-about-progress-bar[data-progress="92"] {
    width: 92%;
  }
  
  .homepage-about-progress-bar[data-progress="91"] {
    width: 91%;
  }
  
  .homepage-about-progress-bar[data-progress="95"] {
    width: 95%;
  }
  
  .homepage-about-mission {
    margin-top: 20px;
    text-align: center;
  }
  
  .homepage-about-mission h3 {
    font-size: 1.2em;
    color: #df693b;
    margin-bottom: 10px;
    font-weight: 700;
  }
  
  .homepage-about-mission-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .homepage-about-mission-item {
    flex: 1 1 120px;
    background-color: #333333;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: white;
    max-width: 200px;
  }
  
  .homepage-about-mission-icon {
    font-size: 45px;
    color: #df693b;
    margin-bottom: 10px;
  }
  
  .homepage-about-vision {
    margin-top: 20px;
    text-align: center;
  }
  
  .homepage-about-vision h3 {
    font-size: 1.2em;
    color: #df693b;
    margin-bottom: 10px;
    font-weight: 700;
  }
  
  .homepage-about-vision p {
    margin: 20px 0;
    color: #666666;
  }
  
  .homepage-about-learn-more {
    padding: 10px 20px;
    background-color: #ff8c00;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .homepage-about-learn-more:hover {
    background-color: #df693b;
  }
  
  .homepage-about-image {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .homepage-about-section {
      flex-direction: column;
      /* align-items: center; */
    }
    
    .homepage-about-left,
    .homepage-about-right {
      max-width: 100%;
    }
  
    .homepage-about-mission-items {
      justify-content: center;
      /* flex-direction: ; */
    }
    .homepage-about-sect{
     flex-direction: row;
     text-align: left;
     
    }
    .homepage-about-service{
      flex-direction: column;
      text-align: left;
    }
  }
  





  .home-domain-container {

    border-radius: 8px;
    padding: 50px 20px;
    background-color: #f8f9fa;
    text-align: center;
    /* max-width: 1200px; */
    margin: auto; /* Center content horizontally */
  }
  
  .home-domain-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .home-domain-sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .home-domain-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    margin: 20px;
  }
  
  .home-domain-icon {
    font-size: 40px;
    color: orange;
    margin-bottom: 10px;
  }
  
  .home-domain-heading {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  @media (max-width: 300px) {
    .home-domain-section{
        width: calc(40%-10px);
    }
  }




  .services-info {
    margin: 20px auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    padding: 0 20px;
}

.services-info-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
}

.services-info-item {
    flex: 0 1 calc(30% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    background-color: #fff;
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s, color 0.3s;
    cursor: pointer;
    text-align: center;

}

.services-info-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #ff0000; /* Orange background on hover */
    color: #fff; /* White text on hover */
}

.services-info-logo {
    margin-bottom: 10px;
    background-color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
}

.services-info-item:hover .services-info-logo {
    background-color: #fff; /* Keep the logo background white on hover */
}

.services-info-icon {
    font-size: 2em;
    color: #ff0000; /* Orange color for icons */
    transition: color 0.3s;
}

.services-info-item:hover .services-info-icon {
    color: #333; /* Dark grey icon color on hover */
}

.services-info-heading {
    margin-top: 10px;
    font-size: 1.2em;
    color: #121d29;
    transition: color 0.3s;
}

.services-info-item:hover .services-info-heading {
    color: #fff; /* White heading color on hover */
}

.services-info-description {
    color: #666;
    text-align: justify;
    font-size: 12px;
    transition: color 0.3s;
}

.services-info-item:hover .services-info-description {
    color: #fff; /* White description text on hover */
}

@media (max-width: 768px) {
    .services-info-item {
        flex-basis: calc(45% - 20px);
    }
}

@media (max-width: 576px) {
    .services-info-item {
        flex-basis: 100%;
    }
}





.carousel-home-domains-info {
  text-align: center;
  margin: 30px 40px; /* Center content horizontally */
}


.carousel-home-domains-title {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #333;
}

.carousel-home-domains-item {
  display: flex !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;

}


.carousel-home-domains-logo {
  margin-bottom: 10px;
  width: 70px; /* Adjust size as needed */
  height: 70px; /* Adjust size as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;

border-radius: 50px;}

.carousel-home-domains-icon {
  font-size: 2em;
  color: #f6b419;

}

.carousel-home-domains-heading {
  margin-top: 10px;
  font-size: 0.9em;
  color: #09111b;
}

.slick-slide {
  display: flex;
  justify-content: center;
}

.slick-prev:before,
.slick-next:before {
  color: #007bff;
}












.advance-techno-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  text-align: left;
}

.advance-techno-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  gap: 20px;
}

.advance-techno-image img {
  max-width: 100%;
  height: 400px;
}

.advance-techno-info {
  max-width: 700px;
}

.advance-techno-history {
  color: #FF5A5F;
  font-size: 1em;
  margin-bottom: 10px;
}

.advance-techno-title {
  font-size: 1.4em;
  color: #2b2b2b;
  margin-bottom: 20px;
}

.advance-techno-description {
  font-size: .9em;
  color: #353535;
  margin-bottom: 20px;
  line-height: 1.5;
  text-align: justify;
  
}

.advance-techno-about {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.advance-techno-play-button {
  background-color: #ed6700;
  /* border-radius: 50%; */
  max-width: 1000px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-icon {
  color: white;
  font-size: 1em;
}

.advance-techno-about-text .about-us {
  font-size: 1em;
  color: #2b2b2b;
  font-weight: bold;
  margin: 0;
}

.advance-techno-about-text .watch-process {
  font-size: 0.9em;
  color: #666;
  margin: 0;
}

.advance-techno-stats {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
}

.stat-item {
  text-align: center;
  margin: 10px;
  min-width: 100px;
}

.stat-value {
  font-size: 1.5em;
  color: #2b2b2b;
  margin-bottom: 5px;
}

.stat-description {
  font-size: 0.8em;
  color: #666;
}


.faq-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.faq-title {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 40px;
}

.faq-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.faq-card {
  flex: 1;
  min-width: 300px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.faq-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.faq-question {
  font-size: 1.5em;
  color: #444;
  margin-bottom: 10px;
}

.faq-answer {
  font-size: 1em;
  color: #666;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .faq-container {
    flex-direction: column;
    align-items: center;
  }
}







.faq-section {
  max-width: 1200px;
  margin: 0px auto;
  padding: 10px;

}

.faq-heading {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  position: relative;
}

.faq-heading::after {
  content: '';
  display: block;
  height: 4px;
  background-color: #ff0000;
  margin: 10px auto 0;
}

.faq-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.faq-item {
  margin-bottom: 2px;
}

.faq-question {
  cursor: pointer;
  padding: 15px 15px;
  background-color: #f7f7f7;
  color: #333;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  transition: background-color 0.3s;
  position: relative;
}

.faq-question:hover {
  background-color: #fff8f8;
}

.faq-question::after {
  content: '';
  display: block;
  width: calc(100% - 40px);
  height: 2px;
  background-color: #ff5e00;
  position: absolute;
  bottom: 0;
  left: 20px;
  transition: width 0.3s;
}

.faq-question:hover::after {
  width: 100%;
}

.faq-toggle-icon {
  font-size: 22px;
  color: #007bff;
  transition: transform 0.3s;
}

.faq-answer {
  padding: 15px 20px;
  background-color: #f0f8ff;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 11px;
  line-height: 1.6;
  color: #555;
  transition: max-height 0.3s;
  text-align: left;
}
