/* Performance Marketing Banner */
.performance-marketing-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #fdfdfd;
    max-width: 1200px;
    margin: 0 auto; /* Center the banner within its container */
  }
  
  .performance-marketing-banner-content {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .performance-marketing-banner-image {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .performance-marketing-banner-heading {
    margin-top: 0;
    text-align: left;
  }
  
  .performance-marketing-banner-content p {
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .performance-marketing-banner-content button {
    padding: 10px 20px;
    background-color: #ff7113;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: 0 auto;
  }
  
  .performance-marketing-banner-content button:hover {
    background-color: #a54809;
  }
  
  .performance-marketing-banner-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
  }
  
  @media (max-width: 768px) {
    .performance-marketing-banner {
      flex-direction: column;
    }
  
    .performance-marketing-banner-content, 
    .performance-marketing-banner-image {
      max-width: 100%;
    }
  }
  
  /* Performance Marketing Platform Info */
  .performance-marketing-platform-info {
    margin: 20px auto; /* Center the container within its parent */
    max-width: 1200px; /* Set the max-width */
    display: flex;
    flex-wrap: wrap; /* Wrap items onto multiple lines */
    justify-content: space-between; /* Distribute items evenly */
  }
  
  .performance-marketing-platform-info-title {
    width: 100%;
    text-align: center;
    margin-bottom: 20px; /* Add space below the title */
  }
  
  .performance-marketing-platform-info-item {
    flex: 0 1 calc(30% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Light background color */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .performance-marketing-platform-info-logo {
    margin-bottom: 10px;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Make logo container round */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .performance-marketing-platform-info-logo img {
    width: 80px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    object-fit: contain; /* Maintain aspect ratio */
  }
  
  .performance-marketing-platform-info-heading {
    margin-top: 0;
    text-align: center; /* Center platform name */
    font-size: 1rem;
  }
  
  .performance-marketing-platform-info-description {
    color: #666;
    text-align: justify; /* Justify description text */
    font-size: 0.8rem;
  }
  
  @media (max-width: 768px) {
    .performance-marketing-platform-info-item {
      flex-basis: calc(42% - 20px); /* Set width for two items per row on tablets with spacing */
    }
  }
  
  @media (max-width: 576px) {
    .performance-marketing-platform-info-item {
      flex-basis: 100%; /* Set width for one item per row on mobile phones with spacing */
    }
  }




  .embrace-performance-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .embrace-performance-heading {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .embrace-performance-subheading {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 30px;
  }
  
  .embrace-performance-domains {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .embrace-performance-domain-item {
    flex: 0 1 calc(20% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9; /* Light background color */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .embrace-performance-domain-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .embrace-performance-dark {
    background-color: #ff8000;
    color: #000000;
  }
  
  .embrace-performance-light {
    background-color: #fff;
    color: #000000;
  }
  
  .embrace-performance-domain-title {
    font-size: 1em;
    margin-bottom: 10px;
  }
  
  .embrace-performance-domain-description {
    font-size: 0.8em;
    color: #000000;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .embrace-performance-domain-item {
      flex-basis: calc(45% - 20px);
    }
  }
  
  @media (max-width: 576px) {
    .embrace-performance-domain-item {
      flex-basis: 100%;
    }
  }
  
  