/* General styles for both sections */
section {
  padding: 50px 20px;
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
}

section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Case Studies section */
.case-studies {
  margin-bottom: 50px;
}

.case-study-list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.case-study-item {
  flex: 1 1 calc(33% - 40px);
  max-width: calc(33% - 40px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
}

.case-study-item:hover {
  transform: translateY(-10px);
}

.case-study-item h3 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #555;
  text-transform: capitalize;
}

.case-study-item p {
  color: #666;
  font-size: 1rem;
}

/* Brands We Work With section */
.brands-we-work-with {
  margin-top: 50px;
}

.brands-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
  align-items: center;
}

.brand-item {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.brand-item:hover {
  transform: translateY(-10px);
}

.brand-logo {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.brand-item:hover .brand-logo {
  transform: scale(1.1);
}

.brand-item p {
  color: #666;
  font-size: 1rem;
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .case-study-item, .brand-item {
    flex: 1 1 calc(100% - 10px);
    max-width: calc(100% - 10px);
  }
}

@media (max-width: 480px) {
  .case-study-item, .brand-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
