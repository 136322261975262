.homepage-banner {
  position: relative;
  text-align: center;
  color: white;
  padding: 0;
  margin: 0;
}

.homepage-banner-image {
  width: 100%;
  max-height: 100vh;
  min-height: 70vh;
  height: 100%;
  filter: brightness(50%);
}

.homepage-banner-content {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 90%; /* Adjust width for better responsiveness */
}

.homepage-banner-content p {
  font-style: italic;
  margin-bottom: 20px;
  font-size: 1vw; /* Responsive font size */
}

.homepage-banner-content h1 {
  font-style: italic;
  margin-bottom: 20px;
  font-size: 2.5vw; /* Responsive font size */
}

.homepage-banner-content strong {
  font-size: 1vw; /* Responsive font size */
  color: orange;
}

.homepage-cta-button {
  padding: 10px 20px;
  background-color: #ff8401;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2vw; /* Responsive font size */
}

.homepage-cta-button:hover {
  background-color: #ffb326;
}

/* Media Queries for different screen sizes */
@media (max-width: 1200px) {
  .homepage-banner-content p {
    font-size: 1.2vw;
  }

  .homepage-banner-content h1 {
    font-size: 3.5vw;
  }

  .homepage-banner-content strong {
    font-size: 1.2vw;
  }

  .homepage-cta-button {
    font-size: 1.5vw;
  }
}

@media (max-width: 768px) {
  .homepage-banner-content p {
    font-size: 2vw;
  }

  .homepage-banner-content h1 {
    font-size: 4.5vw;
  }

  .homepage-banner-content strong {
    font-size: 2vw;
  }

  .homepage-cta-button {
    font-size: 2vw;
  }
}

@media (max-width: 480px) {
  .homepage-banner-content p {
    font-size: 2.5vw;
  }

  .homepage-banner-content h1 {
    font-size: 5.5vw;
  }

  .homepage-banner-content strong {
    font-size: 2.5vw;
  }

  .homepage-cta-button {
    font-size: 2.5vw;
  }
}







  .about-whoweare-container {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    color: #000;

  }
  
  .about-whoweare-content {
    flex: 1;
    padding-left: 20px;
    text-align: justify;
  }
  
  .about-whoweare-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about-whoweare-divider {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .about-whoweare-line {
    width: 50px;
    height: 4px;
    background-color: #ff4500;
    margin-right: 10px;
  }
  
  .about-whoweare-text {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .about-whoweare-image {
    flex: 1;
    margin-top: 20px;
  }
  
  .about-whoweare-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .about-whoweare-container {
      flex-direction: column;
      text-align: center;
    }
  
    .about-whoweare-content {
      padding-left: 0;
    }
  
    .about-whoweare-image {
      margin-top: 20px;
    }
  }

  












  


  .about-AdvanceTechno-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    color: #333;
  }
  
  .about-AdvanceTechno-content {
    flex: 0 0 60%;
    padding-right: 20px;
    text-align: justify;
  }
  
  .about-AdvanceTechno-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .about-AdvanceTechno-divider {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .about-AdvanceTechno-line {
    width: 50px;
    height: 4px;
    background-color: #ff4500;
    margin-right: 10px;
  }
  
  .about-AdvanceTechno-text {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .about-AdvanceTechno-image {
    flex: 0 0 40%;
  }
  
  .about-AdvanceTechno-image img {
    width: 100%;
    height: auto;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .about-AdvanceTechno-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .about-AdvanceTechno-content {
      flex: 1 1 100%;
      padding-right: 0;
      margin-bottom: 20px;
    }
  
    .about-AdvanceTechno-image {
      flex: 1 1 100%;
    }
  }

  








  .about-pack-of-digital-marketers-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    color: #333;
  }
  
  .about-pack-of-digital-marketers-content {
    flex: 0 0 60%;
    padding-right: 20px;
  }
  
  .about-pack-of-digital-marketers-title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
  }
  
  .about-pack-of-digital-marketers-divider {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .about-pack-of-digital-marketers-line {
    width: 50px;
    height: 4px;
    background-color: #ff4500;
    margin-right: 10px;
    text-align: justify;
  }
  
  .about-pack-of-digital-marketers-text {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .about-pack-of-digital-marketers-list {
    list-style: none;
    padding: 0;
    text-align: justify;
  }
  
  .about-pack-of-digital-marketers-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .about-pack-of-digital-marketers-icon {
    margin-right: 10px;
    font-size: 1.5em;
  }
  
  .about-pack-of-digital-marketers-image {
    flex: 0 0 50%;
  }
  
  .about-pack-of-digital-marketers-image img {
    width: 80%;
    height: auto;
    /* padding: 10px; */
    margin: 10px;
    border-radius: 50px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .about-pack-of-digital-marketers-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .about-pack-of-digital-marketers-content {
      flex: 1 1 100%;
      padding-right: 0;
      margin-bottom: 20px;
    }
  
    .about-pack-of-digital-marketers-image {
      flex: 1 1 100%;
    }
  }

  








  .about-webelive-in {
    padding: 10px 0;
    /* background-color: #f5f5f5; */
    text-align: center;
    margin-bottom: 50px;
  }
  
  .about-webelive-in h2 {
    font-size: 32px;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
  }
  
  .about-webelive-in p {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    max-width: 1330px;
    margin: 0 auto 10px;
    line-height: 1.5;
    text-align: justify;
    padding: 20px;
  }

  .about-webelive-in-card img{
    filter: brightness(40%);
  }
  
  .about-webelive-in-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    /* aspect-ratio: 1/1; */
    
  }
  
  .about-webelive-in-card {
    background-color: #9796963f;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1 1 calc(33.333% - 0px);
    max-width: calc(33.333% - 0px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    aspect-ratio: 1 / 1; /* Ensures the cards are squares */
    max-width: 300px;


  }
  
  .about-webelive-in-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .about-webelive-in-card h3 {
    font-size: 20px; /* Reduced size */
    font-weight: 400;
    color: #333;
    margin-bottom: 10px; /* Reduced margin */
  }
  
  .about-webelive-in-card p {
    font-size: 15px;
    font-weight: 400;
    color: #666;
    line-height: 1.5;
    margin: 0;
  }
  
  .about-webelive-in-card::before {
    content: "";
    display: block;
    width: 60px;
    height: 4px;
    background-color: #ff8c00; /* Accent color */
    margin-bottom: 10px; /* Reduced margin */
  }
  
  @media (max-width: 992px) {
    .about-webelive-in-card {
      flex: 1 1 calc(50% - 40px);
      max-width: calc(50% - 40px);
    }
  }
  
  @media (max-width: 768px) {
    .about-webelive-in-cards {
      margin: 0 40px; /* Added margin to the container */
    }
    .about-webelive-in-card {
      flex: 1 1 calc(100% - 20px); /* Adjusted for margin */
      max-width: calc(100% - 20px);
      margin: 10px 0; /* Added margin to the cards */
    }
  }
  



  .about-orange-banner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
  }
  
  .about-orange-banner__item {
    flex-basis: calc(20% - 10px);
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .about-orange-banner__item:nth-child(1) {
    background-color: #ff9900; /* orange */
  }
  
  .about-orange-banner__item:nth-child(2) {
    background-color: #34c759; /* green */
  }
  
  .about-orange-banner__item:nth-child(3) {
    background-color: #0099ff; /* blue */
  }
  
  .about-orange-banner__item:nth-child(4) {
    background-color: #ff2ce3; /* pink */
  }
  
  .about-orange-banner__number {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .about-orange-banner__text {
    font-size: 12px;
    line-height: 1.5;
    color: #fff;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .about-orange-banner__item {
      flex-basis: 100%;

    }
  }
  
  @media (max-width: 480px) {
    .about-orange-banner__item {
      flex-basis: 100%;
    }
  }