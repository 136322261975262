/* General Styles */
.seo-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* SEO Domains Container */
  .seo-domains-container {
    background-color: #ffffff;
    color: #000000;
    margin-bottom: 40px;
  }
  
  .seo-domains-title {
    text-align: center;
    color: #ff6700; /* Orange color */
    margin-bottom: 20px;
  }
  
  .seo-domains-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px;
  }
  
  .seo-domain-item {
    flex: 1 1 calc(20% - 10px);
    padding: 20px;
    margin: 10px;
    border: 1px solid #ff6700; /* Orange color */
    border-radius: 5px;
  }
  
  .seo-domain-item.light {
    background-color: #f9f9f9;
  }
  
  .seo-domain-item.dark {
    background-color: #333333;
    color: #ffffff;
  }
  
  .seo-domain-item h3 {
    margin-bottom: 10px;
    color: #ff6700; /* Orange color */
  }
  
  .seo-domain-item p {
    margin-bottom: 10px;
  }
  
  /* SEO Testimonials Container */
  .seo-testimonials-container {
    background-color: #f9f9f9;
    color: #000000;
  }
  
  .seo-testimonials-title {
    text-align: center;
    color: #ff6700; /* Orange color */
    margin-bottom: 20px;
  }
  
  .seo-testimonials-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px;
  }
  
  .seo-testimonial-item {
    flex: 1 1 calc(20% - 10px);
    padding: 20px;
    margin: 10px;
    border: 1px solid #ff6700; /* Orange color */
    border-radius: 5px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
  }
  
  .seo-testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
    border: 2px solid #ff6700; /* Orange color */
  }
  
  .seo-testimonial-text {
    flex: 1;
  }
  
  .seo-testimonial-text h3 {
    margin-bottom: 5px;
    color: #ff6700; /* Orange color */
  }
  
  .seo-testimonial-text p {
    margin-bottom: 5px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .seo-domain-item,
    .seo-testimonial-item {
      flex: 1 1 calc(100% - 20px);
    }
  
    .seo-domain-item {
      margin: 10px 0;
    }
  
    .seo-testimonial-item {
      margin: 10px 0;
    }
  
    .seo-testimonial-image {
      width: 60px;
      height: 60px;
    }
  }
  
  @media (max-width: 480px) {
    .seo-domains-title,
    .seo-testimonials-title {
      font-size: 1.5rem;
    }
  
    .seo-domain-item h3,
    .seo-testimonial-text h3 {
      font-size: 1.2rem;
    }
  
    .seo-testimonial-text p {
      font-size: 0.9rem;
    }
  }
  