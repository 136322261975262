.policy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 60px;
    font-family: Arial, sans-serif;
    line-height: 1.6;

    
  }
  
  .policy-container h2, .policy-container h3, .policy-container h4 {
    color: #007bff;
  }
  
  .policy-container h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .policy-container h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .policy-container h4 {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  .policy-container p, .policy-container ul, .policy-container address {
    text-align: justify;
  }
  
  .policy-container ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .policy-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .policy-container a:hover {
    text-decoration: underline;
  }
  
  .policy-container address {
    font-style: normal;
    margin-top: 10px;
  }
  