.footer {
  background-color: black;
  color: white;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  padding: 0 10px;
  /* padding-bottom: 100px; */
}

.footer-section {
  flex: 1 1 20%;
  min-width: 200px;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
}

.footer-section h4 {
  font-size: 16px;
  margin-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  font-size: 14px;
  margin-bottom: 5px;
}

.footer-section ul li a {
  color: white;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-logo {
  width: 100px;
  height: 100px;
  display: block;
  margin-bottom: 10px;
}

address {
  font-style: normal;
  line-height: 1.5;
}

address a {
  color: white;
  text-decoration: none;
}

address a:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #444;
  padding: 20px 0;
  max-width: 1200px;
  margin: auto;
  padding: 0 10px;
}

.newsletter {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 250px;
  margin: 10px 0;
}

.newsletter span {
  font-size: 14px;
  margin-right: 10px;
}

.newsletter-input {
  display: flex;
  align-items: center;
  border: 1px solid white;
  border-radius: 3px;
  overflow: hidden;
}

.newsletter-input input {
  border: none;
  padding: 5px;
  color: black;
}

.newsletter-input button {
  background-color: white;
  color: black;
  border: none;
  padding: 5px 10px;
}

.footer-social {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  /* min-width: 250px; */
  margin: 10px 0;
}

.footer-social a {
  margin-left: 10px;
  font-size: 20px;
  color: white;
  text-decoration: none;
}

.footer-social a:hover {
  color: #ddd;
}

.footer-legal {
  text-align: center;
  border-top: 1px solid #444;
  padding-top: 10px;
  max-width: 1200px;
  margin: auto;
  padding: 0 10px;
  padding-bottom: 60px;
}

.footer-legal p {
  font-size: 12px;
  margin: 5px 0;
}

.footer-legal a {
  color: white;
  text-decoration: none;
}

.footer-legal a:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .footer-container, .footer-bottom, .footer-legal {
    padding: 10px;
  }

  .footer-section, .newsletter, .footer-social {
    flex: 1 1 100%;
    text-align: left;
  }

  .footer-social {
    justify-content: flex-start;
  }

  .footer-legal {
    text-align: left;
    padding-bottom: 60px;
  }
}
