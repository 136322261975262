/* Branding Banner */
.branding-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #fdfdfd;
    max-width: 1200px;
    margin: 0 auto; /* Center the banner within its container */
}

.branding-banner-content {
    flex: 1;
    max-width: 50%;
    padding: 20px;
}

.branding-banner-image {
    flex: 1;
    max-width: 30%;
    padding: 20px;
}

.branding-banner-heading {
    margin-top: 0;
    text-align: left;
    font-size: 18px;
}

.branding-banner-content p {
    margin-bottom: 20px;
    text-align: justify;
    font-size: 13px;
}

.branding-banner-content button {
    padding: 10px 20px;
    background-color: #ff7113;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin: 0 auto;
}

.branding-banner-content button:hover {
    background-color: #a54809;
}

.branding-banner-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .branding-banner {
        flex-direction: column;
    }

    .branding-banner-content, 
    .branding-banner-image {
        max-width: 100%;
    }
}

/* Branding Container */
.branding-our-services-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.branding-our-services-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.branding-our-services-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
}

.branding-our-services-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.branding-our-services-item {
    flex: 1 1 30%;
    max-width: 33%;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: left;
}

.branding-our-services-icon {
    font-size: 50px;
    color: #ff7700eb;
    margin-bottom: 15px;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

.branding-our-services-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.branding-our-services-item p {
    font-size: 14px;
    color: #333;
}

@media (max-width: 768px) {
    .branding-our-services-item {
        flex: 1 1 100%;
        max-width: 100%;
    }
}

/* Domains Container */
.branding-domains-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.branding-domains-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.branding-domains-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
}

.branding-domains-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.branding-domain {
    flex: 0 1 calc(20% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.branding-domain:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.branding-domain.dark {
    background-color: #ff8000;
    color: #000000;
}

.branding-domain.light {
    background-color: #fff;
    color: #000000;
}

.branding-domain-title {
    font-size: 1em;
    margin-bottom: 10px;
}

.branding-domain-description {
    font-size: 0.8em;
    color: #000000;
    text-align: justify;
}

@media (max-width: 768px) {
    .branding-domain {
        flex-basis: calc(45% - 20px);
    }
}

@media (max-width: 576px) {
    .branding-domain {
        flex-basis: 100%;
    }
}

/* CTA Container */
.branding-cta-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.branding-cta-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.branding-cta-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
}

.branding-cta-button {
    padding: 10px 20px;
    background-color: #ff7113;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.branding-cta-button:hover {
    background-color: #a54809;
}

/* Testimonials Container */
.branding-testimonials-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.branding-testimonials-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.branding-testimonials-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 30px;
}

.branding-testimonials-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.branding-testimonial {
    flex: 1 1 30%;
    max-width: 33%;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    /* text-align: left; */
}

.branding-testimonial-image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    border: 2px solid white;
}

.branding-testimonial-content h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    align-items: center;
}

.branding-testimonial-content p {
    font-size: 14px;
    color: #333;
}

.branding-testimonial-rating span {
    color: #ff4800;
    font-size: 18px;
    align-items: center;
}

@media (max-width: 768px) {
    .branding-testimonial {
        flex: 1 1 100%;
        max-width: 100%;
    }
}
