.contactus-web-container {
    display: flex;
    flex-direction: row;
    /* height: 100vh; */
  
  }
  
  .contactus-web-left {
    background: url('../../media/worktop.webp') no-repeat center center;
    background-size: cover;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    text-align: center;
    padding: 20px;
  }
  
  .contactus-web-right {
    flex: 1;
    background: transparent;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .contactus-web-right h2 {
    margin-bottom: 10px;
  }
  
  .contactus-web-right p {
    margin-bottom: 20px;
  }
  
  .contactus-web-form {
    display: flex;
    flex-direction: column;
  }
  .contactus-web-form-group {
    display: flex;
    width: 100%; /* Ensure the container uses the full width */
    flex-wrap: wrap; /* Allow the items to wrap if necessary */
    gap: 20px; /* Optional: add gap between the input boxes */
  }
  
  .contactus-web-form-group input {
    flex:calc(50% - 10px); /* Set width to 50% minus half of the gap */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    margin-bottom: 20px; /* Add space below each input box */
  }
  
  .contactus-web-form-group select {
    flex:calc(45% - 10px); /* Set width to 50% minus half of the gap */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    margin-bottom: 20px; /* Add space below each input box */
  }
  
  
  .contactus-web-input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Set background color with opacity */
  }
  
  .contactus-web-input.full-width {
    width: 100%;
  }
  
  .contactus-web-submit {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .contactus-web-partners {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .contactus-web-partners img {
    max-height: 50px;
    margin: 5px;
  }
  .conatctus-form-12{
    flex-direction: row;
    gap: 10px;
    width: 100%;
    padding-bottom: 20px;
  }
  
  /* Responsive Design */
  
  
  @media (max-width: 768px) {
  
  
    .contactus-web-container{
      display: flex;
      flex-direction: column-reverse;
    }
  
    .contactus-web-left {
      height: 100vh;
    }
    .contactus-web-right {
      padding: 10px;
    }
    .contactus-web-form {
      display: flex;
      flex-direction: column;
      padding: 0px;
      margin-right: 0px;
      width: 100%;
    }
    .contactus-web-form-group {
      display: flex;
      flex-wrap: wrap; /* Allow the items to wrap if necessary */
      width: calc(100% - 10px);
      flex-direction: row;
    }
    .conatctus-form-12{
      gap: 10px;
      width: 100%;
    }
    
  
  
  
    
    
  }
  
  
  .contactus-web-form-group-1 {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Add some gap between the fields */
  }
  
  /* Ensure proper sizing and display on larger screens */
  .contactus-web-form-group-1 .contactus-web-select,
  .contactus-web-form-group-1 .contactus-web-input {
    flex: 1 1 calc(33.33% - 10px); /* Each field takes one-third of the available space, minus the gap */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  /* Adjust the layout for smaller screens */
  @media (max-width: 768px) {
    .contactus-web-form-group-1 {
      flex-direction: column;
    }
  
    .contactus-web-form-group-1 .contactus-web-select {
      flex: 1 1 100%; /* Full width for the services select box */
    }
  
    .contactus-web-form-group-1 .contactus-web-input {
      flex: 1 1 calc(50% - 10px); /* Half width for date and time fields */
    }
    .contactus-web-select{
      padding: 0px;
      margin: 0px;
      height: 37px;
    }
    .select__control, .contactus-web-select  {
      margin-bottom: 20px;
    }
  
    
  
  
  
  }
  
   .select__control, .contactus-web-select css-b62m3t-container css-13cymwt-control {
    margin-bottom: 20px;
  }
  
  /* Ensure the parent container handles content expansion correctly */
  .contactus-web-form-group-1 {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Add some gap between the fields */
    width: 100%;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  /* Style the select component container */
  .contactus-web-select {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%; /* Default to full width */
    box-sizing: border-box;
    margin-bottom: 10px; /* Add some margin at the bottom */
  }
  
  /* Ensure proper height adjustment */
  .contactus-web-select .select__control {
    height: auto; /* Allow height to adjust based on content */
  }
  
  /* Media queries to adjust layout based on screen size */
  @media (max-width: 768px) {
    .contactus-web-select {
      flex-basis: calc(50% - 10px); /* Adjust for medium screens */
    }
  }
  
  @media (max-width: 576px) {
    .contactus-web-select {
      flex-basis: 100%; /* Full width for smaller screens */
    }
  }
  








  /* Modal Styles */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal h2 {
  margin-top: 0;
}

.modal p {
  margin: 15px 0;
}

.modal button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #0056b3;
}


.contactus-web-submit {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.contactus-web-submit:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
