/* Basic reset for margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    background: #f8f9fa;
  }
  
  .presentation-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    /* max-width: 1200px; */
    margin: 0 auto; /* Center horizontally */
    position: relative; /* Needed for background animation */
    overflow: hidden; /* Hide overflow for background animation */
  }
  
  .text-content {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .text-content h1 {
    font-size: 2.5em;
    color: #000;
    margin-bottom: 20px;
  }
  
  .text-content p {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  .button {
    background-color: #ff0000;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .button:hover {
    background-color: #cc0000;
    transform: scale(1.05);
  }
  
  .image-content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  @media (min-width: 768px) {
    .presentation-container {
      flex-direction: row;
    }
  
    .text-content {
      text-align: left;
      max-width: 50%;
    }
  
    .image-content {
      max-width: 50%;
    }
  }
  
  /* Background animation */
  .presentation-container::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,0,0,0.2), transparent 70%);
    animation: rotateBackground 10s linear infinite;
    z-index: -1; /* Place behind other content */
  }


  
  
  @keyframes rotateBackground {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  