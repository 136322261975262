.terms-container {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;

  }
  
  .terms-container h2, .terms-container h3, .terms-container h4 {
    color: #007bff;
  }
  
  .terms-container h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .terms-container h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .terms-container h4 {
    margin-top: 15px;
    margin-bottom: 10px;
  }
  
  .terms-container p, .terms-container ul, .terms-container address {
    text-align: justify;
  }
  
  .terms-container ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .terms-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-container a:hover {
    text-decoration: underline;
  }
  
  .terms-container address {
    font-style: normal;
    margin-top: 10px;
  }
  