.call-to-action-container {
    background-color: #ff5722; /* Match the orange color */
    padding: 30px 0;
    text-align: center;
  }
  
  .call-to-action-text {
    color: #fff; /* White text */
    font-size: 1em; /* Adjust font size as needed */
    font-weight: bold; /* Bold text */
    margin: 0;
  }
  
  .call-to-action-text a {
    color: #000000; /* White link color */
    text-decoration: underline; /* Underline link */
    font-weight: 1000;
    font-size: 2em;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  
  .call-to-action-text a:hover {
    text-decoration: none; /* Remove underline on hover */
  }
  